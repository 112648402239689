import axios from "axios";

export default class ReservationsSchedulesCancel {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationScheduleToken = "";
    this.cancelReasonAr = "";
    this.cancelReasonEn = "";
    this.cancelReasonUnd = "";
    this.cancelNote = "";
    this.methodPaidToken = "";
    this.refundPercentageFromPaid = "";
  }
  fillData(data) {
    this.reservationScheduleToken = data.reservationScheduleToken;
    this.cancelReasonAr = data.cancelReasonAr;
    this.cancelReasonEn = data.cancelReasonEn;
    this.cancelReasonUnd = data.cancelReasonUnd;
    this.cancelNote = data.cancelNote;
    this.methodPaidToken = data.methodPaidToken;
    this.refundPercentageFromPaid = data.refundPercentageFromPaid;
  }

  async cancelReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationScheduleToken: this.reservationScheduleToken,
      cancelReasonAr: this.cancelReasonAr,
      cancelReasonEn: this.cancelReasonEn,
      cancelReasonUnd: this.cancelReasonUnd,
      cancelNote: this.cancelNote,
      methodPaidToken: this.methodPaidToken,
      refundPercentageFromPaid: this.refundPercentageFromPaid,
    };

    try {
      let response = await axios.post(
        `/ReservationsSchedulesCancel/EmployeeCancelServicesReservation`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
