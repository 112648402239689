import { PAGE_SIZE } from "@/utils/constants";
import axios from "axios";

export default class Reservation {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.reservationToken = "";
    this.enquiryReservationToken = "";
    this.reservationCode = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.clientToken = "";
    this.clientCode = "";
    this.clientNameCurrent = "";
    this.clientPhone = "";
    this.clientPhoneWithCC = "";
    this.clientImagePath = "";
    this.activityToken = "";
    this.activityNameCurrent = "";
    this.activityImagePath = "";
    this.placeToken = "";
    this.placeCode = "";
    this.placeImagePath = "";
    this.placeNameCurrent = "";
    this.placePhone1 = "";
    this.placePhone1WithCC = "";
    this.placePhone2 = "";
    this.placePhone2WithCC = "";
    this.placePhone3 = "";
    this.placePhone3WithCC = "";
    this.placePhone4 = "";
    this.placePhone4WithCC = "";
    this.mapLink = "";
    this.placeLatitude = "";
    this.placeLongitude = "";
    this.placeAddressCurrent = "";
    this.reservationNameCurrent = "";
    this.reservationNameAr = "";
    this.reservationNameEn = "";
    this.reservationNameUnd = "";
    this.reservationDescriptionCurrent = "";
    this.reservationDescriptionAr = "";
    this.reservationDescriptionEn = "";
    this.reservationDescriptionUnd = "";
    this.reservationNotes = "";
    this.actionDateTime = "";
    this.actionDate = "";
    this.actionTime = "";
    this.reservationArchiveStatus = "";
    this.reservationsSchedulesData = [];
  }
  fillData(data) {
    this.reservationToken = data.reservationToken;
    this.enquiryReservationToken = data.enquiryReservationToken;
    this.reservationCode = data.reservationCode;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.clientToken = data.clientToken;
    this.clientCode = data.clientCode;
    this.clientNameCurrent = data.clientNameCurrent;
    this.clientPhone = data.clientPhone;
    this.clientPhoneWithCC = data.clientPhoneWithCC;
    this.clientImagePath = data.clientImagePath;
    this.activityToken = data.activityToken;
    this.activityNameCurrent = data.activityNameCurrent;
    this.activityImagePath = data.activityImagePath;
    this.placeToken = data.placeToken;
    this.placeCode = data.placeCode;
    this.placeImagePath = data.placeImagePath;
    this.placeNameCurrent = data.placeNameCurrent;
    this.placePhone1 = data.placePhone1;
    this.placePhone1WithCC = data.placePhone1WithCC;
    this.placePhone2 = data.placePhone2;
    this.placePhone2WithCC = data.placePhone2WithCC;
    this.placePhone3 = data.placePhone3;
    this.placePhone3WithCC = data.placePhone3WithCC;
    this.placePhone4 = data.placePhone4;
    this.placePhone4WithCC = data.placePhone4WithCC;
    this.mapLink = data.mapLink;
    this.placeLatitude = data.placeLatitude;
    this.placeLongitude = data.placeLongitude;
    this.placeAddressCurrent = data.placeAddressCurrent;
    this.reservationNameCurrent = data.reservationNameCurrent;
    this.reservationNameAr = data.reservationNameAr;
    this.reservationNameEn = data.reservationNameEn;
    this.reservationNameUnd = data.reservationNameUnd;
    this.reservationDescriptionCurrent = data.reservationDescriptionCurrent;
    this.reservationDescriptionAr = data.reservationDescriptionAr;
    this.reservationDescriptionEn = data.reservationDescriptionEn;
    this.reservationDescriptionUnd = data.reservationDescriptionUnd;
    this.reservationNotes = data.reservationNotes;
    this.actionDateTime = data.actionDateTime;
    this.actionDate = data.actionDate;
    this.actionTime = data.actionTime;
    this.reservationArchiveStatus = data.reservationArchiveStatus;
    this.reservationsSchedulesData = data.reservationsSchedulesData;
  }

  async getAllReservations(
    language,
    userAuthorizeToken,
    pagination,
    filterData
  ) {
    try {
      let response = await axios.get(
        `/Reservations/GetAllReservations?language=${language}&userAuthorizeToken=${userAuthorizeToken}&page=${pagination.selfPage}&pageSize=${PAGE_SIZE}&filterStatus=true&paginationStatus=true&includeStatus=true&activityToken=${filterData.activityToken}&placeToken=${filterData.placeToken}&clientToken=${filterData.clientToken}&textSearch=${filterData.textSearch}`
      );
      return response;
    } catch (error) {
      return this.$t("errorCatch");
    }
  }

  async getReservationsDetails(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Reservations/GetReservationsDetails?language=${language}&userAuthorizeToken=${userAuthorizeToken}&reservationToken=${this.reservationToken}&includeStatus=true`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async getDialogOfReservations(language, userAuthorizeToken) {
    try {
      let response = await axios.get(
        `/Reservations/GetDialogOfReservations?language=${language}&userAuthorizeToken=${userAuthorizeToken}`
      );
      return response;
    } catch (error) {
      console.log(error);
      return this.$t("errorCatch");
    }
  }

  async addReservation(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      enquiryReservationToken: this.enquiryReservationToken,
      reservationDescriptionAr: this.reservationDescriptionAr,
      reservationDescriptionEn: this.reservationDescriptionEn,
      reservationDescriptionUnd: this.reservationDescriptionUnd,
      reservationNotes: this.reservationNotes,
    };

    try {
      let response = await axios.post(`/Reservations/AddReservations`, data);
      console.log(response);
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
