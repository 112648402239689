<template>
  <div v-if="reservationsData" class="">
    <div
      v-for="(reservation, index) in reservationsData"
      :key="index"
      class="container-collapse-with-btns"
    >
      <b-button
        v-b-toggle="`item${index}`"
        class="btn btn-lg btn-collapse collapse-data"
      >
        <div class="p-t-container">
          <div class="row collapse-title">
            {{ isDataExist(reservation.reservationNameCurrent) }}
          </div>
          <div class="collapse-icon">
            <span class="counter">{{ index + 1 }}</span>
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </div>
        </div>
      </b-button>
      <div class="collapse-actions btn-collapse">
        <!-- <td>
          <router-link
            :to="`/reservations/reservation-details/${item.reservationToken}`"
            :title="$t('info')"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </router-link>
        </td> -->
        <!-- <td>
          <button
            v-b-modal.ReservationCancel
            class="btn p-0"
            :title="$t('cancel')"
            @click="setReservationData(item)"
          >
            <img src="@/assets/images/cancel.svg" class="icon-lg" />
          </button>
        </td> -->
        <div>
          <button
            v-b-modal.ReservationInfo
            class="btn p-0"
            :title="$t('info')"
            @click="setReservationData(reservation)"
          >
            <img src="@/assets/images/info.svg" class="icon-lg" />
          </button>
        </div>
        <div>
          <button
            v-b-modal.ReservationQRCode
            class="btn p-0"
            :title="$t('reservations.QRcode')"
            @click="setReservationData(reservation)"
          >
            <img src="@/assets/images/qr-code.svg" class="icon-lg" />
          </button>
        </div>
      </div>
      <b-collapse :id="`item${index}`">
        <!-- #region reservation -->
        <div class="table-container">
          <table class="my-table">
            <thead>
              <tr>
                <th>{{ $t("reservations.code") }}</th>
                <th class="cell-lg">{{ $t("clientName") }}</th>
                <th>{{ $t("activities.name") }}</th>
                <th>{{ $t("places.code") }}</th>
                <th class="cell-lg">{{ $t("places.name") }}</th>
                <th>{{ $t("actionDateTime") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ isDataExist(reservation.reservationCode) }}</td>
                <td>{{ isDataExist(reservation.clientNameCurrent) }}</td>
                <td>{{ isDataExist(reservation.activityNameCurrent) }}</td>
                <td>{{ isDataExist(reservation.placeCode) }}</td>
                <td>{{ isDataExist(reservation.placeNameCurrent) }}</td>
                <td>
                  {{
                    formateDateTimeLang(
                      reservation.actionDate,
                      reservation.actionTime
                    )
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #endregion reservation -->

        <!-- #region reservationsSchedules -->
        <div
          v-if="reservation.reservationsSchedulesData"
          class="table-container"
        >
          <table class="my-table">
            <thead>
              <tr>
                <th>#</th>
                <th>{{ $t("enquiryReservations.scheduleCode") }}</th>
                <th>{{ $t("day") }}</th>
                <th>{{ $t("enquiryReservations.fromDateTime") }}</th>
                <th>{{ $t("enquiryReservations.toDateTime") }}</th>
                <th>{{ $t("enquiryReservations.duration") }}</th>
                <th>{{ $t("enquiryReservations.totalPrice") }}</th>
                <th>{{ $t("enquiryReservations.totalPaid") }}</th>
                <th>{{ $t("enquiryReservations.remainingAmount") }}</th>
                <th>{{ $t("notes") }}</th>
                <!-- <th>{{ $t("status") }}</th> -->
                <!-- <th v-if="!isClient">
                  {{ $t("reservations.approveSchedule") }}
                </th> -->
                <th v-if="!isClient">{{ $t("reservations.pay") }}</th>
                <th>{{ $t("reservations.cancel") }}</th>
                <th>{{ $t("info") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  item, indexSchedule
                ) in reservation.reservationsSchedulesData"
                :key="indexSchedule"
              >
                <td>{{ ++indexSchedule }}</td>
                <td>{{ isDataExist(item.reservationScheduleCode) }}</td>
                <td>{{ isDataExist(item.dayNameCurrent) }}</td>
                <td>
                  {{ formateDateTimeLang(item.dateFrom, item.timeFrom) }}
                </td>
                <td>
                  {{ formateDateTimeLang(item.dateTo, item.timeTo) }}
                </td>
                <td>{{ isDataExist(item.actualDurationTime) }}</td>
                <td>{{ moneyFormat(item.actualPrice) }}</td>
                <td>{{ moneyFormat(item.totalPaid) }}</td>
                <td>{{ moneyFormat(item.remainingAmount) }}</td>
                <td>{{ isDataExist(item.enquiryReservationScheduleNotes) }}</td>
                <!-- <td>
                  {{ isDataExist(item.reservationScheduleStatusNameCurrent) }}
                </td> -->
                <!-- <td v-if="!isClient">
                  <button
                    v-if="
                      item.reservationScheduleStatusToken ==
                      reservationScheduleStatus.waitingApproval
                    "
                    v-b-modal.ReservationScheduleApprove
                    class="btn p-0"
                    :title="$t('reservations.approveSchedule')"
                    @click="setReservationScheduleData(item)"
                  >
                    <img
                      src="@/assets/images/approve-schedule.svg"
                      class="icon-lg"
                    />
                  </button>
                  <span v-else>-</span>
                </td> -->
                <td v-if="!isClient">
                  <button
                    v-if="
                      item.remainingAmount > 0 && item.cancelStatus == false
                    "
                    v-b-modal.ReservationSchedulePay
                    class="btn p-0"
                    :title="$t('reservations.pay')"
                    @click="setReservationScheduleData(item)"
                  >
                    <img src="@/assets/images/money.svg" class="icon-lg" />
                  </button>
                  <span v-else>-</span>
                </td>
                <td>
                  <button
                    v-if="
                      item.finishStatus == false && item.cancelStatus == false
                    "
                    v-b-modal.ReservationScheduleCancel
                    class="btn p-0"
                    :title="$t('reservations.cancel')"
                    @click="setReservationScheduleData(item)"
                  >
                    <img src="@/assets/images/cancel.svg" class="icon-lg" />
                  </button>
                  <span v-if="item.cancelStatus == true">
                    {{ $t("reservations.canceled") }}
                  </span>
                  <span v-else-if="item.finishStatus == true">
                    {{ $t("reservations.finished") }}
                  </span>
                </td>
                <td>
                  <button
                    v-b-modal.ReservationScheduleInfo
                    class="btn p-0"
                    :title="$t('info')"
                    @click="setReservationScheduleData(item)"
                  >
                    <img src="@/assets/images/info.svg" class="icon-lg" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- #endregion reservationsSchedules -->
      </b-collapse>
    </div>
  </div>
</template>

<script>
import {
  isDataExist,
  formateDateTimeLang,
  moneyFormat,
} from "@/utils/functions";
import { RESERVATION_SCHEDULE_STATUS } from "@/utils/constantLists";

export default {
  name: "ReservationsTable",
  // components: {},
  data() {
    return {
      isClient: false,
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      reservationScheduleStatus: RESERVATION_SCHEDULE_STATUS,
    };
  },
  props: ["reservationsData"],
  methods: {
    async setUserType() {
      if (this.$store.getters.userData.clientPersonalData) {
        this.isClient = true;
      }
    },
    setReservationData(enquiryReservationData) {
      this.$emit("setReservationData", enquiryReservationData);
    },
    setReservationScheduleData(reservationScheduleData) {
      this.$emit("setReservationScheduleData", reservationScheduleData);
    },
    isDataExist(data) {
      return isDataExist(data);
    },
    moneyFormat(data) {
      return moneyFormat(data);
    },
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
  computed: {},
  async created() {
    await this.setUserType();
  },
};
</script>
