import axios from "axios";

export default class ReservationsSchedulesPayment {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.payToken = "";
    this.establishmentToken = "";
    this.establishmentNameCurrent = "";
    this.payCode = "";
    this.placeToken = "";
    this.placeNameCurrent = "";
    this.activityToken = "";
    this.activityNameCurrnet = "";
    this.reservationScheduleToken = "";
    this.reservationScheduleCode = "";
    this.reservationScheduleNameCurrent = "";
    this.remainingAmount = "";
    this.payUserToken = "";
    this.payUserNameCurrent = "";
    this.clientToken = "";
    this.clientNameCurrent = "";
    this.clientCode = "";
    this.clientPhoneWithCC = "";
    this.methodPaidToken = "";
    this.methodPaidNameCurrent = "";
    this.methodPaidValue = "";
    this.walletValue = "";
    this.pointsValue = "";
    this.onePointEqualNumberMoneyValue = "";
    this.paidActual = "";
    this.payDateTime = "";
    this.payDate = "";
    this.payTime = "";
    this.payNote = "";
    this.refundStatus = "";
    this.refundDateTime = "";
    this.refundDate = "";
    this.refundTime = "";
    this.refundUserToken = "";
    this.refundUserNameCurrent = "";
    this.refundReasonCurrent = "";
    this.refundReasonAr = "";
    this.refundReasonEn = "";
    this.refundReasonUnd = "";
    this.refundNotes = "";
    this.payArchiveStatus = "";
  }
  fillData(data) {
    this.payToken = data.payToken;
    this.establishmentToken = data.establishmentToken;
    this.establishmentNameCurrent = data.establishmentNameCurrent;
    this.payCode = data.payCode;
    this.placeToken = data.placeToken;
    this.placeNameCurrent = data.placeNameCurrent;
    this.activityToken = data.activityToken;
    this.activityNameCurrnet = data.activityNameCurrnet;
    this.reservationScheduleToken = data.reservationScheduleToken;
    this.reservationScheduleCode = data.reservationScheduleCode;
    this.reservationScheduleNameCurrent = data.reservationScheduleNameCurrent;
    this.remainingAmount = data.remainingAmount;
    this.payUserToken = data.payUserToken;
    this.payUserNameCurrent = data.payUserNameCurrent;
    this.clientToken = data.clientToken;
    this.clientNameCurrent = data.clientNameCurrent;
    this.clientCode = data.clientCode;
    this.clientPhoneWithCC = data.clientPhoneWithCC;
    this.methodPaidToken = data.methodPaidToken;
    this.methodPaidNameCurrent = data.methodPaidNameCurrent;
    this.methodPaidValue = data.methodPaidValue;
    this.walletValue = data.walletValue;
    this.pointsValue = data.pointsValue;
    this.onePointEqualNumberMoneyValue = data.onePointEqualNumberMoneyValue;
    this.paidActual = data.paidActual;
    this.payDateTime = data.payDateTime;
    this.payDate = data.payDate;
    this.payTime = data.payTime;
    this.payNote = data.payNote;
    this.refundStatus = data.refundStatus;
    this.refundDateTime = data.refundDateTime;
    this.refundDate = data.refundDate;
    this.refundTime = data.refundTime;
    this.refundUserToken = data.refundUserToken;
    this.refundUserNameCurrent = data.refundUserNameCurrent;
    this.refundReasonCurrent = data.refundReasonCurrent;
    this.refundReasonAr = data.refundReasonAr;
    this.refundReasonEn = data.refundReasonEn;
    this.refundReasonUnd = data.refundReasonUnd;
    this.refundNotes = data.refundNotes;
    this.payArchiveStatus = data.payArchiveStatus;
  }

  async newPayment(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      reservationScheduleToken: this.reservationScheduleToken,
      methodPaidToken: this.methodPaidToken,
      methodPaidValue: this.methodPaidValue,
      walletValue: this.walletValue,
      pointsValue: this.pointsValue,
      onePointEqualNumberMoneyValue: this.onePointEqualNumberMoneyValue,
      payNote: this.payNote,
    };

    try {
      let response = await axios.post(
        `/ReservationsSchedulesPayments/NewPayment`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }

  async refundPayment(language, userAuthorizeToken) {
    var data = {
      language: language,
      userAuthorizeToken: userAuthorizeToken,
      payToken: this.payToken,
      refundReasonAr: this.refundReasonAr,
      refundReasonEn: this.refundReasonEn,
      refundReasonUnd: this.refundReasonUnd,
      refundNotes: this.refundNotes,
    };

    try {
      let response = await axios.post(
        `/ReservationsSchedulesPayments/RefundPayment`,
        data
      );
      return response;
    } catch (e) {
      return this.$t("errorCatch");
    }
  }
}
