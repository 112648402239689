export default class ReservationsFilter {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.activityToken = "";
    this.placeToken = "";
    this.clientToken = "";
    this.textSearch = "";
  }
  fillData(data) {
    this.activityToken = data.activityToken;
    this.placeToken = data.placeToken;
    this.clientToken = data.clientToken;
    this.textSearch = data.textSearch;
  }
}
