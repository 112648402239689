<template>
  <b-modal
    id="ReservationScheduleInfo"
    scrollable
    size="lg"
    centered
    hide-footer
  >
    <template #modal-title>
      <h3>
        <img src="@/assets/images/time.svg" class="icon-lg" />
        {{ $t("reservations.ScheduleData") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationScheduleData.reservationScheduleCode"
        :title="$t('enquiryReservations.scheduleCode')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationScheduleData.dayNameCurrent"
        :title="$t('day')"
        :imgName="'days.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            reservationScheduleData.dateFrom,
            reservationScheduleData.timeFrom
          )
        "
        :title="$t('enquiryReservations.fromDateTime')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          formateDateTimeLang(
            reservationScheduleData.dateTo,
            reservationScheduleData.timeTo
          )
        "
        :title="$t('enquiryReservations.toDateTime')"
        :imgName="'dateAndTime.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationScheduleData.actualDurationTime"
        :title="$t('enquiryReservations.duration')"
        :imgName="'time.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(reservationScheduleData.actualPrice)"
        :title="$t('enquiryReservations.totalPrice')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(reservationScheduleData.totalPaid)"
        :title="$t('enquiryReservations.totalPaid')"
        :imgName="'money.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="moneyFormat(reservationScheduleData.remainingAmount)"
        :title="$t('enquiryReservations.remainingAmount')"
        :imgName="'money.svg'"
      />
      <!-- <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationScheduleData.enquiryReservationScheduleNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      /> -->

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          formateDateTimeLang(
            reservationScheduleData.actionDate,
            reservationScheduleData.actionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />

      <!-- <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          reservationScheduleData.employeeActiveStatus === true ? $t('yes') : $t('no')
        "
        :title="$t('employeeActiveStatus')"
        :imgName="'type.svg'"
      /> -->
      <div v-if="reservationScheduleData.cancelStatus" class="col-12 my-card">
        <span class="my-card-title">{{ $t("reservations.refundData") }}</span>

        <div class="row">
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="reservationScheduleData.refoundMethodPaidNameCurrent"
            :title="$t('methodPaidName')"
            :imgName="'method-paids.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="reservationScheduleData.refundPercentage"
            :title="$t('reservations.refundPercentageFromPaid')"
            :imgName="'percentage.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="moneyFormat(reservationScheduleData.refoundValue)"
            :title="$t('reservations.refundValue')"
            :imgName="'money.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="moneyFormat(reservationScheduleData.totalPaidWithRefound)"
            :title="$t('reservations.totalPaidWithRefound')"
            :imgName="'money.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="reservationScheduleData.refoundReasonAr"
            :title="$t('reservations.cancelReasonAr')"
            :imgName="'reason.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="reservationScheduleData.refoundReasonEn"
            :title="$t('reservations.cancelReasonEn')"
            :imgName="'reason.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="reservationScheduleData.refoundReasonUnd"
            :title="$t('reservations.cancelReasonUnd')"
            :imgName="'reason.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="reservationScheduleData.refoundNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { formateDateTimeLang, moneyFormat } from "@/utils/functions";

export default {
  name: "ReservationScheduleInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["reservationScheduleData"],
  methods: {
    moneyFormat,
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
