<template>
  <b-modal id="ReservationInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/reservations.svg" class="icon-lg" />
        {{ $t("reservations.data") }}
      </h3>
    </template>
    <div class="row">
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.reservationCode"
        :title="$t('reservations.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.activityNameCurrent"
        :title="$t('activities.name')"
        :imgName="'type.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.clientCode"
        :title="$t('clientCode')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.clientNameCurrent"
        :title="$t('clientName')"
        :imgName="'clients.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.placeCode"
        :title="$t('places.code')"
        :imgName="'code.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.placeNameCurrent"
        :title="$t('places.name')"
        :imgName="'places.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.reservationDescriptionAr"
        :title="$t('reservations.descriptionAr')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.reservationDescriptionEn"
        :title="$t('reservations.descriptionEn')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.reservationDescriptionUnd"
        :title="$t('reservations.descriptionUnd')"
        :imgName="'description.svg'"
      />
      <DataLabelGroup
        :className="'col-lg-6'"
        :value="reservationData.reservationNotes"
        :title="$t('notes')"
        :imgName="'notes.svg'"
      />

      <DataLabelGroup
        :className="'col-lg-12'"
        :value="
          formateDateTimeLang(
            reservationData.actionDate,
            reservationData.actionTime
          )
        "
        :title="$t('actionDateTime')"
        :imgName="'dateAndTime.svg'"
      />

      <!-- <DataLabelGroup
        :className="'col-lg-6'"
        :value="
          reservationData.employeeActiveStatus === true ? $t('yes') : $t('no')
        "
        :title="$t('employeeActiveStatus')"
        :imgName="'type.svg'"
      /> -->
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "@/components/general/DataLabelGroup.vue";
import { setDataMultiLang, formateDateTimeLang } from "@/utils/functions";

export default {
  name: "ReservationInfo",
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["reservationData"],
  methods: {
    formateDateTimeLang(date, time) {
      return formateDateTimeLang(date, time, this.language);
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
};
</script>

<style scoped lang="scss"></style>
